import React from "react"
import {Helmet} from "react-helmet";

const Community = () => (
  <main>
  <Helmet><meta http-equiv="refresh" content="0; URL='https://www.facebook.com/groups/265876031072797'" /></Helmet>
  </main>
);

export default Community;
